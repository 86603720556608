const COUNTRIES_TOTEAT = ["cl", "ar", "co", "pe", "mx", "es"];

const AVAILABLES_LANG = [
  "es-CL",
  "es-AR",
  "es-CO",
  "es-PE",
  "es-MX",
  "es-ES",
  "en-US",
];

const COUNTRIES_NAMES = {
  cl: "Chile",
  ar: "Argentina",
  co: "Colombia",
  pe: "Perú",
  es: "España",
  mx: "México",
  us: "United States",
};

const COUNTRIES_LANG = {
  cl: {
    text: "Chile (Español)",
    ix: "es-CL",
  },
  ar: {
    text: "Argentina (Español)",
    ix: "es-AR",
  },
  co: {
    text: "Colombia (Español)",
    ix: "es-CO",
  },
  pe: {
    text: "Perú (Español)",
    ix: "es-PE",
  },
  mx: {
    text: "México (Español)",
    ix: "es-MX",
  },
  es: {
    text: "España (Español)",
    ix: "es-ES",
  },
  ca: {
    text: "España (Catalá)",
    ix: "es-CA",
  },
  us: {
    text: "United States (English)",
    ix: "en-US",
  },
};

const WHATSAPP_NUMBERS = {
  cl: "+56995094595",
  ar: "+5492644996503", // Se deja el de Chile hasta solucionar problema de bloqueo número Argentina
  co: "+573028374485",
  pe: "+5215579476671",
  es: "+34932710252",
  mx: "+5215554560077",
  us: "+56995094595",
};

const COUNTRIES_PLANS = {
  cl: {
    basicPlan: {
      taxes: "IVA",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "$39.900",
      percentage: "0,7%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Boleta y factura electrónica ilimitada (DTE)",
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Integración Redelcom Tarjeta Crédito / Débito",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: [],
    },
    advancedPlan: {
      taxes: "IVA",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "$69.900",
      percentage: "0,5%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "IVA",
      name: "CORPORATIVO",
      content:
        "Tú sabes mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "$179.900",
      percentage: "0,35%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
      ],
    },
  },
  ar: {
    basicPlan: {
      taxes: "IVA",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "$24.900",
      percentage: "0,5%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Cobro extra por facturador electrónico*",
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: [
        "* El servicio es brindado por Facturante y existe un ajuste de precios trimestral.",
      ],
    },
    advancedPlan: {
      taxes: "IVA",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "$49.900",
      percentage: "0,35%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "IVA",
      name: "CORPORATIVO",
      content:
        "Vos sabés mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "$89.900",
      percentage: "0,25%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
      ],
    },
  },
  co: {
    basicPlan: {
      taxes: "impuestos",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "$139.900",
      percentage: "0,7%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Factura Electrónica ilimitada (DTE)*",
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: [
        // eslint-disable-next-line
        "* El servicio es brindado por Factura1.",
      ],
    },
    advancedPlan: {
      taxes: "impuestos",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "$269.900",
      percentage: "0,5%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "impuestos",
      name: "CORPORATIVO",
      content:
        "Tú sabes mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "$459.900",
      percentage: "0,35%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
      ],
    },
  },
  pe: {
    basicPlan: {
      taxes: "IGV",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "S/.165",
      percentage: "0,5%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Documentos de facturas electrónicas*", // TODO: 1000 QUÉ
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: ["* Hasta 1000."],
    },
    advancedPlan: {
      taxes: "IGV",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "S/.250",
      percentage: "0,35%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "IGV",
      name: "CORPORATIVO",
      content:
        "Tú sabes mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "S/.350",
      percentage: "0,25%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
      ],
    },
  },
  es: {
    basicPlan: {
      taxes: "IVA",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "20,00 €",
      percentage: "0,5%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Cobro extra por facturador electrónico*",
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: [
        "* El servicio es brindado por Facturante y existe un ajuste de precios trimestral.",
      ],
    },
    advancedPlan: {
      taxes: "IVA",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "45,00 €",
      percentage: "0,4%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "IVA",
      name: "CORPORATIVO",
      content:
        "Tú sabes mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "80,00 €",
      percentage: "0,2%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
      ],
    },
  },
  mx: {
    basicPlan: {
      taxes: "IVA",
      name: "BÁSICO",
      content:
        "Ideal para foodtrucks, pequeñas cafeterías o emprendimientos gastronómicos.",
      price: "$690",
      percentage: "0,6%",
      list: [
        "POS en la nube",
        "Impresión de comandas",
        "Integración Apps Delivery (hasta 3)",
        "Soporte en línea todos los días del año",
        "Capacitaciones gratuitas mensuales",
        "Más de 20 reportes estratégicos",
        "Menú QR Digital",
        "Modo offline",
        "Mesas, usuarios y equipos ilimitados",
        "Inventario en línea",
        "Número de reinstalación de impresoras ilimitado",
        "Integración medios de pago digitales",
        "Toteat Pago QR",
      ],
      specifications: [],
    },
    advancedPlan: {
      taxes: "IVA",
      name: "AVANZADO",
      content:
        "Pensado en negocios gastronómicos medianos que buscan expandirse.",
      price: "$990",
      percentage: "0,4%",
      list: [
        "Integración Apps Delivery (hasta 8)",
        "Pantalla de repartidores y clientes",
        "KDS (Pantalla de cocina)",
        "Ingredientes y costo de recetas",
        "Stock control",
        "Toteat Autoatención",
        "Toteat Delivery",
        "Toteat Pickup",
        "Lector Tarjeta NFC Mesero",
      ],
    },
    enterprisePlan: {
      taxes: "IVA",
      name: "CORPORATIVO",
      content:
        "Tú sabes mejor que nadie el valor de tu información y de contar con todas las herramientas de Toteat.",
      price: "$1,690",
      percentage: "0,3%",
      list: [
        "Integración Apps Delivery (ilimitada)",
        "Transferencia entre sucursales",
        "API Inventarios",
        "API Compras",
        "API Ventas",
        "Toteat Reservas",
        "Sin límite de uso de funciones",
        "Reportería Inventario Multilocal",
        "KAM personalizado",
        "Factura electrónica ilimitada (DTE)",
      ],
    },
  },
  us: {
    basicPlan: {
      taxes: "taxes",
      name: "BASIC",
      content:
        "Ideal for food trucks, small cafeterias or gastronomic enterprises.",
      price: "30 USD",
      percentage: "0,5%",
      list: [
        "Cloud POS",
        "Order printing",
        "App delivery integrations (up to 3)",
        "Extra charge for electronic billing*",
        "Online support 365 days a year",
        "Free monthly training",
        "More than 20 strategic reports",
        "Toteat QR digital menu",
        "Offline mode",
        "Unlimited tables, users and teams",
        "Online inventory",
        "Unlimited equipment and printer reinstallation",
        "Integration of digital payment methods",
        "Toteat QR payment",
      ],
      specifications: [
        "* The service is provided by Facturante and there is a quarterly price adjustment.",
      ],
    },
    advancedPlan: {
      taxes: "taxes",
      name: "ADVANCED",
      content: "Designed for medium gastronomic businesses looking to expand.",
      price: "70 USD",
      percentage: "0,4%",
      list: [
        "App delivery integrations (up to 8)",
        "Dealers and customers screen",
        "KDS (Kitchen Display System)",
        "Ingredients and cost of recipes",
        "Stock control",
        "Toteat Self-service",
        "Toteat Delivery",
        "Toteat Pickup",
        "Waiter NFC Card Reader",
      ],
    },
    enterprisePlan: {
      taxes: "taxes",
      name: "ENTERPRISE",
      content:
        "You know better than anyone the value of your information and of having all the Toteat tools.",
      price: "100 USD",
      percentage: "0,2%",
      list: [
        "App delivery integrations (unlimited)",
        "Transfers between branches",
        "Inventories API",
        "Purchases API",
        "Sales API",
        "Toteat Reservations",
        "No function use limit",
        "Multilocal Inventory Reporting",
        "Custom KAM",
      ],
    },
  },
};

// const COUNTRIES_INTEGRATIONS = {
//   cl: {
//     digitalPayments: ["mercado pago", "chek", "mach"],
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
//   ar: {
//     digitalPayments: {},
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
//   co: {
//     digitalPayments: {},
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
//   pe: {
//     digitalPayments: {},
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
//   es: {
//     digitalPayments: {},
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
//   mx: {
//     digitalPayments: {},
//     devicesPayments: {},
//     billing: {},
//     delivery: {},
//   },
// };

function findCountryCode(name) {
  return Object.keys(COUNTRIES_NAMES).find(
    (key) => COUNTRIES_NAMES[key] === name
  );
}

function getLangFromCountry(code) {
  if (COUNTRIES_TOTEAT.includes(code)) {
    return COUNTRIES_LANG[code].ix;
  } else {
    return COUNTRIES_LANG["cl"].ix;
  }
}

function getUserCountry(asName = false) {
  var code = "cl";
  if (
    window.GLOBALuserCountry &&
    COUNTRIES_TOTEAT.indexOf(window.GLOBALuserCountry) >= 0
  ) {
    code = window.GLOBALuserCountry;
  }
  if (asName) return countryName(code);
  else return code;
}

function getAvailableCountries(asNames = true) {
  if (!asNames) return COUNTRIES_TOTEAT;
  var countries = [];
  COUNTRIES_TOTEAT.forEach((code) => {
    countries.push(COUNTRIES_NAMES[code]);
  });
  countries.sort();
  return countries;
}

function countryName(code) {
  return COUNTRIES_NAMES[code] || "";
}

function countryPlans(code) {
  return COUNTRIES_PLANS[code] || "";
}

function countryWhatsapp(code) {
  if (!code) {
    code = getUserCountry();
  }
  return WHATSAPP_NUMBERS[code] || "";
}

export {
  AVAILABLES_LANG,
  COUNTRIES_NAMES,
  COUNTRIES_LANG,
  findCountryCode,
  getLangFromCountry,
  getAvailableCountries,
  getUserCountry,
  countryName,
  countryPlans,
  countryWhatsapp,
};
